import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import TeamImageSection from "../../components/team-image-section";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "About-Us-hero-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "About-Us-image-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="About Us | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us`,
					title: "About Us | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > About us"
						title="About us"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="py-5 pt-md-0 pb-lg-7">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">Who we are</h2>
								<p>
									Catriona, Karen and Tamsin have all worked together for many
									years in the internationally renowned Facial Palsy
									Multidisciplinary Team at Queen Victoria Hospital NHS
									Foundation Trust.
								</p>
								<p>
									They all have many years of experience working with patients
									who require rehabilitation following facial paralysis and all
									specialise in both facial therapy and botulinum toxin
									injection therapy.
								</p>
								<p>
									{" "}
									Alongside their NHS work they wanted to offer a private
									service for patients who desired quicker access to specialist
									facial palsy care and valued enhanced rehabilitation sessions.
									They therefore set up the Facial Rehabilitation Centre.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 pt-md-0 pb-lg-7">
					<Container>
						<TeamImageSection />
					</Container>
				</section>
				<section className="py-5  py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">
									Conference presentations given by the TFRC team
								</h2>
							</Col>
						</Row>
						<Row>
							<Col xl={6}>
								<h3 className="fs-5 text-dark-bg mt-4">
									Royal Society of Medicine
								</h3>
								<p>
									· Controversies around electrical stimulation in facial
									rehabilitation – a debate
								</p>
								<h3 className="fs-5 text-dark-bg mt-4">
									Multi-disciplinary International Facial Conference
									‘Controversies and Challenges in Facial Palsy; St Andrews
									Centre for Plastic Surgery, Chelmsford
								</h3>
								<p> · Specialist Facial Therapy Outcomes</p>
								<h3 className="fs-5 text-dark-bg mt-4">
									FTSI Conference – Birmingham
								</h3>
								<p> · Chair’s Welcome Speech</p>
								<p>· Management of Ocular Synkinesis</p>
								<h3 className="fs-5 text-dark-bg mt-4"> IFNS Boston 2013</h3>
								<p>
									. The Psychological Impact of a Multidisciplinary Facial Palsy
									Clinic
								</p>
								<h3 className="fs-5 text-dark-bg mt-4"> IFNS LA 2017</h3>
								<p>· Holistic management of ocular synkinesis</p>
								<p>· Development of a facial therapy specialists' group</p>
								<p>· Enhanced MRI scanning in atypical facial palsy</p>
							</Col>
							<Col xl={6}>
								<h3 className="fs-5 text-dark-bg mt-4">
									Nordic Facial Nerve Conference 2021
								</h3>
								<p>· International Consensus on Specialist Facial Therapy</p>
								<h3 className="fs-5 text-dark-bg mt-4">
									{" "}
									IFNS South Korea 2022
								</h3>
								<p>
									· The benefits of an early multi-disciplinary approach in
									facial palsy rehabilitation
								</p>
								<p>
									· Final results of international consensus guidelines study
									for specialist facial therapy
								</p>
								<p>
									· Analysis of the correlation between resting facial symmetry
									and voluntary movement in patients with facial palsy and
									synkinesis pre and post a novel facial therapy group
								</p>
								<p>
									· Multidisciplinary online facial rehabilitation for
									synkinesis (MOFRS)
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 bg-primary">
					<Container className="d-none d-xl-block">
						<Row>
							<Col>
								<h2 className="text-white text-center mb-7">
									Research & book chapters published by the TFRC team
								</h2>
								{/* <p className="text-white text-center mb-7">
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>
						<Row className="g-5 mb-7 h-100">
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										The role of botulinum toxin in the management of facial
										palsy. Neville, C and Young K 2022. In: Facial Palsy; a
										practical guide for trainees, general practitioners, nurses
										and allied health professionals. In production
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										The Role of Botulinum Toxin in Facial Palsy Management.
										Kannan, R.Y. and Nduka, C. (supported by C Neville) 2022.
										In: Management of Post-Facial Paralysis Synkinesis (pp.
										39-50). Elsevier
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Cheung LK, Kannan RY, Neville C, Gwynn T, Young K, Ching RC,
										Nduka C. Streamlining the Diagnosis of Atypical Facial
										Palsies: A 5-year Review of 805 Patients. Plast Reconstr
										Surg Glob Open. 2022 Feb 9;10(2):e4087.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Ullrich K, Dolz-Güerri F, Neville C, Kannan R, Nduka C,
										Litwin A, Malhotra R. Does lagophthalmos change on lying
										supine after upper eyelid platinum segment chain loading?
										Orbit. 2021 Oct;40(5):370-374.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Szczepura A, Holliday N, Neville C, Johnson K, Khan AJK,
										Oxford SW, Nduka C. Raising the Digital Profile of Facial
										Palsy: National Surveys of Patients’ and Clinicians’
										Experiences of Changing UK Treatment Pathways and Views on
										the Future Role of Digital Technology. J Med Internet Res.
										2020 Oct 5;22(10):e20406.
									</p>
								</div>
							</Col>
						</Row>
						<Row className="g-5 mb-7 h-100">
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Venables V, Neville C, Gwynn T, Kannan RY, Nduka C. Impact
										of lower motor neurone facial palsy on oro-motor function
										and its remediation. J Plast Reconstr Aesthet Surg. 2019
										Sep;72(9):1570-1575.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Pescarini E, Neville C, Gwynn T, Young K, Chandrasekharan L,
										Nduka C, Kannan RY. The Need for Routine Assessment of
										Masseter Function After Masseteric Nerve Harvest With the
										Borschel Method. JAMA Facial Plast Surg. 2019 Sep
										1;21(5):461-462.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Kannan RY, Neville C, Nduka C. Vermilion-Mucosal Advancement
										Flap: A Scarless Technique of Adynamic Wedge Resection of
										the Lower Lip in Patients With Facial Palsy. JAMA Facial
										Plast Surg. 2018 Sep 1;20(5):423. doi:
										10.1001/jamafacial.2018.0639.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Kannan RY, Neville C, Gwynn T, Young K, Venables V, Nduka C.
										Evaluation of Masseteric Nerve-Based Selective Neurotization
										for Multivectorial Augmentation of the Weak Smile. JAMA
										Facial Plast Surg. 2019 Jul 1;21(4):340-341.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Cooper L, Izard C, Harries V, Neville C, Venables V,
										Malhotra R, Nduka C. Paradoxical Frontalis Activation: An
										Underrecognized Consequence of Facial Palsy. Plast Reconstr
										Surg. 2018 Feb;141(2):263e-270e.
									</p>
								</div>
							</Col>
						</Row>
						<Row className="g-5 mb-7 h-100">
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Kannan RY, Neville C, Gwynn T, Venables V, Malhotra R, Nduka
										C. The effect of template-based sequential (TBS) coding on
										an NHS plastic surgical practice. J Plast Reconstr Aesthet
										Surg. 2018 Jul;71(7):1058-1061.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Szczepura, A., Khan, A., Holliday, N., Nduka, C., Neville,
										C., Johnson, K., Mistry, H. and Oxford, S., 2018. PP26
										facial palsy therapy: can novel ‘smart spectacles’ help
										people smile? International Journal of Technology Assessment
										in Health Care, 34(S1), pp.76-76.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Neville C, Venables V, Aslet M, Nduka C, Kannan R. An
										objective assessment of botulinum toxin type A injection in
										the treatment of post-facial palsy synkinesis and
										hyperkinesis using the synkinesis assessment questionnaire.
										J Plast Reconstr Aesthet Surg. 2017 Nov;70(11):1624-1628.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Ziahosseini K, Venables V, Neville C, Nduka C, Patel B,
										Malhotra R. Occurrence and severity of upper eyelid skin
										contracture in facial nerve palsy. Eye (Lond). 2016
										May;30(5):713-7.
									</p>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} xl={true}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Pucks N, Thomas A, Hallam MJ, Venables V, Neville C, Nduka
										C. Cutaneous cooling to manage botulinum toxin
										injection-associated pain in patients with facial palsy: A
										randomised controlled trial. J Plast Reconstr Aesthet Surg.
										2015 Dec;68(12):1701-5.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
					<Container className="d-xl-none">
						<Row>
							<Col>
								<h2 className="text-white text-center mb-7">
									Research & Book Chapters Published By The TFRC Team
								</h2>
								{/* <p className="text-white text-center mb-7">
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
							</Col>
						</Row>
						<Row className="g-5 mb-7  h-100">
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										The role of botulinum toxin in the management of facial
										palsy. Neville, C and Young K 2022. In: Facial Palsy; a
										practical guide for trainees, general practitioners, nurses
										and allied health professionals. In production
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										The Role of Botulinum Toxin in Facial Palsy Management.
										Kannan, R.Y. and Nduka, C. (supported by C Neville) 2022.
										In: Management of Post-Facial Paralysis Synkinesis (pp.
										39-50). Elsevier
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Cheung LK, Kannan RY, Neville C, Gwynn T, Young K, Ching RC,
										Nduka C. Streamlining the Diagnosis of Atypical Facial
										Palsies: A 5-year Review of 805 Patients. Plast Reconstr
										Surg Glob Open. 2022 Feb 9;10(2):e4087.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Ullrich K, Dolz-Güerri F, Neville C, Kannan R, Nduka C,
										Litwin A, Malhotra R. Does lagophthalmos change on lying
										supine after upper eyelid platinum segment chain loading?
										Orbit. 2021 Oct;40(5):370-374.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Szczepura A, Holliday N, Neville C, Johnson K, Khan AJK,
										Oxford SW, Nduka C. Raising the Digital Profile of Facial
										Palsy: National Surveys of Patients’ and Clinicians’
										Experiences of Changing UK Treatment Pathways and Views on
										the Future Role of Digital Technology. J Med Internet Res.
										2020 Oct 5;22(10):e20406.
									</p>
								</div>
							</Col>

							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Venables V, Neville C, Gwynn T, Kannan RY, Nduka C. Impact
										of lower motor neurone facial palsy on oro-motor function
										and its remediation. J Plast Reconstr Aesthet Surg. 2019
										Sep;72(9):1570-1575.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Pescarini E, Neville C, Gwynn T, Young K, Chandrasekharan L,
										Nduka C, Kannan RY. The Need for Routine Assessment of
										Masseter Function After Masseteric Nerve Harvest With the
										Borschel Method. JAMA Facial Plast Surg. 2019 Sep
										1;21(5):461-462.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Kannan RY, Neville C, Nduka C. Vermilion-Mucosal Advancement
										Flap: A Scarless Technique of Adynamic Wedge Resection of
										the Lower Lip in Patients With Facial Palsy. JAMA Facial
										Plast Surg. 2018 Sep 1;20(5):423. doi:
										10.1001/jamafacial.2018.0639.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Kannan RY, Neville C, Gwynn T, Young K, Venables V, Nduka C.
										Evaluation of Masseteric Nerve-Based Selective Neurotization
										for Multivectorial Augmentation of the Weak Smile. JAMA
										Facial Plast Surg. 2019 Jul 1;21(4):340-341.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Cooper L, Izard C, Harries V, Neville C, Venables V,
										Malhotra R, Nduka C. Paradoxical Frontalis Activation: An
										Underrecognized Consequence of Facial Palsy. Plast Reconstr
										Surg. 2018 Feb;141(2):263e-270e.
									</p>
								</div>
							</Col>

							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Kannan RY, Neville C, Gwynn T, Venables V, Malhotra R, Nduka
										C. The effect of template-based sequential (TBS) coding on
										an NHS plastic surgical practice. J Plast Reconstr Aesthet
										Surg. 2018 Jul;71(7):1058-1061.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Szczepura, A., Khan, A., Holliday, N., Nduka, C., Neville,
										C., Johnson, K., Mistry, H. and Oxford, S., 2018. PP26
										facial palsy therapy: can novel ‘smart spectacles’ help
										people smile? International Journal of Technology Assessment
										in Health Care, 34(S1), pp.76-76.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Neville C, Venables V, Aslet M, Nduka C, Kannan R. An
										objective assessment of botulinum toxin type A injection in
										the treatment of post-facial palsy synkinesis and
										hyperkinesis using the synkinesis assessment questionnaire.
										J Plast Reconstr Aesthet Surg. 2017 Nov;70(11):1624-1628.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Ziahosseini K, Venables V, Neville C, Nduka C, Patel B,
										Malhotra R. Occurrence and severity of upper eyelid skin
										contracture in facial nerve palsy. Eye (Lond). 2016
										May;30(5):713-7.
									</p>
								</div>
							</Col>
							<Col className="book-card" md={4} lg={3}>
								<div
									style={{ borderRadius: "20px" }}
									className="bg-white position-relative h-100 p-4 pt-5"
								>
									<div className="position-absolute top-0 start-50 translate-middle">
										<StaticImage
											style={{ maxWidth: "60px" }}
											src="../../images/SVG - Icon blue book circle.svg"
											quality={100}
											placeholder="traced_svg"
										/>
									</div>
									<p
										style={{ fontSize: "80%" }}
										className="text-center text-dark-bg"
									>
										Pucks N, Thomas A, Hallam MJ, Venables V, Neville C, Nduka
										C. Cutaneous cooling to manage botulinum toxin
										injection-associated pain in patients with facial palsy: A
										randomised controlled trial. J Plast Reconstr Aesthet Surg.
										2015 Dec;68(12):1701-5.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5  py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-4">
									Training courses given by TFRC team
								</h2>
								<p className="text-center mb-5">
									Foundation courses in facial palsy management
								</p>
							</Col>
						</Row>
						<Row className="g-5">
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">East Grinstead</h3>
									</div>
								</div>
							</Col>
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">Newcastle</h3>
									</div>
								</div>
							</Col>
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">Birmingham</h3>
									</div>
								</div>
							</Col>
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">Guildford</h3>
									</div>
								</div>
							</Col>
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">Cork, Ireland</h3>
									</div>
								</div>
							</Col>
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">London</h3>
									</div>
								</div>
							</Col>
							<Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">Canada</h3>
									</div>
								</div>
							</Col>
							{/* <Col md={6} lg={4} xl={3}>
								<div
									style={{ border: "3px solid #EFF3F6" }}
									className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
								>
									<div className="text-center py-5">
										<StaticImage
											style={{ maxWidth: "150px" }}
											src="../../images/SVG - Icon location.svg"
											quality={100}
											className=" mb-4 "
											placeholder="traced_svg"
										/>
										<h3 className="text-dark-bg fs-4">Service 8</h3>
									</div>
								</div>
							</Col> */}
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 position-relative mt-4">
					<div
						style={{ height: "400px" }}
						className="position-absolute d-none d-lg-block start-0 top-0 w-30  bg-light-grey"
					></div>
					<Container>
						<Row className="g-5 g-lg-7">
							<Col className=" text-center text-lg-start pb-5 pb-lg-0" lg={6}>
								<div style={{ borderRadius: "21px", overflow: "hidden" }}>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
							<Col lg={6}>
								{" "}
								<h2 className=" text-dark-bg pb-4 ">
									Advanced course in facial palsy management
								</h2>
								{/* <p>
									Nam libero tempore, cum soluta nobis est eligendi optio cumque
									nihil impedit quo minus id quod maxime placeat facere
									possimus.
								</p> */}
								<p>. QVH</p>
								<p>. Cork, Ireland</p>
								<p>
									· Training webinars introduced & given by TFRC team members
								</p>
								<p>· FTSI Advanced Webinar – Management of Synkinesis</p>
								<p>
									· FTSI Advanced Webinar – Eye management and post-surgical
									rehabilitation
								</p>
								<p>
									· FTSI Advaced Webinar – Assessment and Management of
									Paediatric Facial Palsy Patients
								</p>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default AboutUsPage;
