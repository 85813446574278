import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import gsap from "gsap";
import { Button } from "react-bootstrap";

const TeamImageSection = ({ review }) => {
	const data = useStaticQuery(graphql`
		query {
			imgOne: wpMediaItem(title: { eq: "Trina" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img2: wpMediaItem(title: { eq: "Karen" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img3: wpMediaItem(title: { eq: "TG" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);

	const enterAnimation = (btn) => {
		gsap.to(
			btn,

			{ opacity: 1, duration: 0.5 }
		);
	};
	const exitAnimation = (btn) => {
		gsap.to(btn, { opacity: 0, duration: 0.5 });
	};

	return (
		<Row>
			<Col className="text-center mb-5 mb-lg-0" lg={4}>
				<div
					onMouseEnter={() => enterAnimation(".bg-button-1")}
					onMouseLeave={() => exitAnimation(".bg-button-1")}
					className="w-100 position-relative "
					style={{
						overflow: "hidden",
					}}
				>
					<div
						style={{
							opacity: 0,
							zIndex: 3,
							background:
								"transparent linear-gradient(180deg, rgba(60, 104, 175, 0.69) 0%, rgba(60, 104, 175, 0.69) 100%) 0% 0% no-repeat padding-box",
						}}
						className="position-absolute text-center d-flex align-items-center justify-content-center bg-button-1 w-100 h-100 top-0 start-0"
					>
						<div className="d-block px-4 px-lf-6">
							<h3 className="text-white">Catriona Neville</h3>
							<p className="text-white">
								Extended Scope Practitioner Facial Therapist
							</p>
							<Button
								as={Link}
								to="/about-us/catriona-neville"
								className="px-3  w-100 w-md-auto text-white mt-1"
								variant="secondary"
							>
								{" "}
								View Profile
							</Button>
						</div>
					</div>
					<GatsbyImage
						image={data.imgOne.localFile.childImageSharp.gatsbyImageData}
						alt={data.imgOne.altText}
					/>
				</div>
			</Col>
			<Col className="text-center mb-5 mb-lg-0" lg={4}>
				<div
					onMouseEnter={() => enterAnimation(".bg-button-2")}
					onMouseLeave={() => exitAnimation(".bg-button-2")}
					className="w-100 position-relative "
					style={{
						overflow: "hidden",
					}}
				>
					<div
						style={{
							opacity: 0,
							zIndex: 3,
							background:
								"transparent linear-gradient(180deg, rgba(60, 104, 175, 0.69) 0%, rgba(60, 104, 175, 0.69) 100%) 0% 0% no-repeat padding-box",
						}}
						className="position-absolute text-center d-flex align-items-center justify-content-center bg-button-2 w-100 h-100 top-0 start-0"
					>
						<div className="d-block px-4 px-lf-6">
							<h3 className="text-white">Karen Young</h3>
							<p className="text-white">Advanced Facial Therapist</p>
							<Button
								as={Link}
								to="/about-us/karen-young"
								className="px-3  w-100 w-md-auto text-white mt-1"
								variant="secondary"
							>
								{" "}
								View Profile
							</Button>
						</div>
					</div>
					<GatsbyImage
						image={data.img2.localFile.childImageSharp.gatsbyImageData}
						alt={data.img2.altText}
					/>
				</div>
			</Col>
			<Col className="text-center" lg={4}>
				<div
					onMouseEnter={() => enterAnimation(".bg-button-3")}
					onMouseLeave={() => exitAnimation(".bg-button-3")}
					className="w-100 position-relative "
					style={{
						overflow: "hidden",
					}}
				>
					<div
						style={{
							opacity: 0,
							zIndex: 3,
							background:
								"transparent linear-gradient(180deg, rgba(60, 104, 175, 0.69) 0%, rgba(60, 104, 175, 0.69) 100%) 0% 0% no-repeat padding-box",
						}}
						className="position-absolute text-center d-flex align-items-center justify-content-center bg-button-3 w-100 h-100 top-0 start-0"
					>
						<div className="d-block px-4 px-lf-6">
							<h3 className="text-white">Tamsin Gwynn</h3>
							{/* <p className="text-white">
								Et harum quidem rerum facilis est et expedita distinctio
							</p> */}
							<Button
								as={Link}
								to="/about-us/tamsin-gwynn"
								className="px-3  w-100 w-md-auto text-white mt-1"
								variant="secondary"
							>
								{" "}
								View Profile
							</Button>
						</div>
					</div>
					<GatsbyImage
						image={data.img3.localFile?.childImageSharp.gatsbyImageData}
						alt={data.img3.altText}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default TeamImageSection;
